import { VideoResolution } from "@sod/models/enum-view";

export class UpdateToken {
  static readonly type = '[User] UpdateToken';

  constructor(public token: string) { }
}
export class UpdateUserId {
  static readonly type = '[User] UserId';

  constructor(public userId: number) { }
}
export class LoginSite {
  static readonly type = '[Site] site';

  constructor(public site: 'admin'|'learning') { }
}

export class Logout {
  static readonly type = '[User] Logout';

}
export class UpdateVideoResolution {
  static readonly type = '[User] UpdateVideoResolution';
  constructor(public videoResolution: VideoResolution[]) { }
}

export class UpdateSettingVideoResolution {
  static readonly type = '[User] UpdateVideoResolution';
  constructor(public videoResolution: VideoResolution[]) { }
}
export class ResetSettingVideoResolution {
  static readonly type = '[User] ResetVideoResolution';
}
